.videos > div {
  height: 550px !important;
  width: 1170px !important;


  @media (max-width: 768px) {
    width: 768px !important;
  }

  @media (max-width: 1440px) {
    width: 1000px !important;
  }

  @media (min-width: 1920px){
    width: 1525px !important;
    height: 725px !important;
  }

}

.videos {
  overflow-x: auto;
}
