//  Core

.app-header-menu-custom {
  position: relative;
  transition: $transition-base;
  transform: scale(1);
  visibility: visible;
  transition-delay: 0.2s;

  @include media-breakpoint-down(xs) {
    display: none;
  }
}
